<template>
  <section
    class="trf-component04 d-flex align-items-center position-relative overflow-hidden"
    :class="props.data?.Right && 'end'"
    :style="{
      backgroundImage:
        'url(' +
        'https://asensoft.online' +
        props.data?.Background?.data?.attributes?.url +
        ')',
    }"
  >
    <div
      class="container"
      :class="props.data?.Right && 'd-flex justify-content-end'"
    >
      <div
        v-if="props.data?.Description"
        class="row h-100 trf-component04__wrap"
        :class="props.data?.Right && 'text-end d-flex justify-content-end'"
      >
        <h2>{{ props.data?.Name }}</h2>
        <p v-html="parsedMarkdown(props.data?.Description)"></p>

        <div v-if="props.data?.Image" class="our-clients">
          <p>Nuestros clientes:</p>
          <div class="logos">
            <img
              :src="
                'https://asensoft.online' +
                props.data?.Image?.data?.attributes?.url
              "
              alt=""
            />
          </div>
        </div>

        <CcButton
          template="Template-5"
          text="Solicitar cotización"
          hideIcon="true"
          :link="
            props.data?.Url +
            '?text=Hola Transportes Rafael, estoy interesado en el servicio: ' +
            props.data?.Name
          "
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import defineProps from "vue";
import { onMounted, computed, ref } from "vue";
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt();

const props = defineProps({
  title: String,
  description: String,
  items: Array,
  data: Object,
});

const parsedMarkdown = (val) => markdown.render(val);
</script>

<style lang="scss">
.trf-component04 {
  width: 100%;
  height: 550px;
  // background-image: url(../../../images/gallery/R.jpg);
  background-size: cover;
  background-position: center;
  border-top: 3px solid #fff;

  &__wrap {
    width: 650px;
    line-height: 1.2;
    h2 {
      line-height: 1.2;
      font-weight: 500;
      color: #fff;
      font-size: 45px;
      margin-bottom: 1rem;
    }
    p {
      color: #fff;
    }

    .our-clients {
      p {
        margin-top: 1.3rem;
        font-weight: 600;
      }
      img {
        width: 160px;
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-component04 {
    height: 600px;
    &__wrap {
      width: 100%;
      padding: 0 1rem;
      h2 {
        padding: 0;
        font-size: 30px;
      }
      > p {
        margin: 0;
      }
      p {
        padding: 0;
        font-size: 15px;
      }

      .our-clients {
        padding: 0;
        p {
        }
        img {
        }
      }
    }
  }
}

@media (min-width: 1990px) {
  .trf-component04 {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 3rem;
  }
}
</style>
