<template>
  <section
    class="trf-vehicles d-flex align-items-center position-relative overflow-hidden"
    v-if="props.data && activeId"
  >
    <div class="container d-flex flex-column align-items-center text-center">
      <div
        class="row h-100 trf-vehicles__wrap justify-content-center px-4 px-md-0"
      >
        <h2 v-html="parsedMarkdown(props.data?.Name)"></h2>
        <div class="divider"></div>
        <p>
          {{ props.data?.Description }}
        </p>
      </div>

      <div class="row justify-content-center py-4">
        <div
          class="align-items-center col-12 d-flex flex-column flex-md-row gap-3 gap-md-0 justify-content-center vehicles-items"
        >
          <div
            v-for="i in props.data?.Vehicles"
            class="mx-2 d-flex justify-content-center align-items-center"
            v-bind:class="i.id === activeId?.id && 'active'"
            @click="setActive(i)"
          >
            <img
              :src="
                'https://asensoft.online' + i.Image.data.attributes.url
              "
              alt=""
              class="img-fluid mb-4"
            />
            <p>{{ i.Name }}</p>
          </div>
        </div>

        <div class="col-lg-10 col-md-12 px-4 px-4 d-none d-md-flex">
          <div
            class="align-items-center flex-column justify-content-center m-auto mt-5 ps-0 ps-md-5 pt-4 row vehicles-description w-100"
          >
            <div
              class="vehicles-content col-6 d-flex flex-column justify-content-center"
            >
              <img
                :src="
                  'https://asensoft.online' +
                  activeId.ActiveImage.data.attributes.url
                "
                alt=""
              />
              <p class="title">{{ activeId.Name }}</p>
              <p class="lh-1 pt-2 pb-4">
                {{ activeId.Description }}
              </p>

              <div class="properties">
                <p v-if="activeId.Seats">
                  <b>Asientos:</b> {{ activeId.Seats }}
                </p>
                <p v-if="activeId.Capacity">
                  <b>Capacidad de carga:</b> {{ activeId.Capacity }}
                </p>
                <p v-if="activeId.Dimentions">
                  <b>Dimensiones:</b> {{ activeId.Dimentions }}
                </p>
              </div>
            </div>

            <div class="col-6 d-flex justify-content-end">
              <form action="">
                <h2>Solicita una <b>cotización</b></h2>

                <div>
                  <label
                    v-for="input in formModel"
                    class="d-flex flex-column text-start"
                    for=""
                  >
                    {{ input.label }}
                    <input
                      type="text"
                      v-model="formData[input.modelName]"
                      :placeholder="input.placeholder"
                      v-if="input.type === 'text'"
                    />
                    <textarea
                      v-if="input.type === 'textarea'"
                      v-model="formData[input.modelName]"
                      :placeholder="input.placeholder"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </label>
                  <div>
                    <span @click="doSubmit()">
                      <CcButton
                        text="Cotizar servicio"
                        :disabled="!isValidForm"
                        template="Template-2"
                        :link="'javascript:;'"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import { ref, defineProps, onMounted, watch, computed } from "vue";
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt();
const isValidForm = ref(false);
const activeId = ref(null);
const formModel = [
  {
    type: "text",
    label: "Nombres completos",
    placeholder: "Ingresa tus nombres",
    modelName: "names",
  },
  {
    type: "text",
    label: "Correo corporativo",
    placeholder: "Ingresa tu correo",
    modelName: "email",
  },
  {
    type: "text",
    label: "Número de teléfono",
    placeholder: "Ingresa tu número",
    modelName: "phone",
  },
  {
    type: "textarea",
    label: "Comentarios",
    placeholder: "Describe lo que necesitas",
    modelName: "message",
  },
];

const formData = ref({});
const parsedMarkdown = (val) => markdown.render(val);

watch(
  formData.value,
  async (e) => {
    const isValid = formModel
      .map((f) => formData.value[f.modelName])
      .filter((f) => !f);
    isValidForm.value = isValid.length === 0;
  },
  { immediate: true }
);

const doSubmit = () => {
  let text =
    "Hola Transportes Rafael, solicito información de su flota:%0A %0A";
  text += "Nombres completos: " + formData.value["names"] + "%0A";
  text += "Correo electrónico: " + formData.value["email"] + "%0A";
  text += "Teléfono: " + formData.value["phone"] + " %0A";
  text += "Detalle de servicio: " + formData.value["message"] + " ";
  const url = `https://wa.me/+51908898445?text=${text}`;
  window.open(url, "_blank");
  emit("close");
};

const props = defineProps({
  title: String,
  description: String,
  items: Array,
  data: {
    type: Object,
    default: null,
  },
});

onMounted(async () => {
  activeId.value = props.data?.Vehicles[0];
});

watch(
  () => props.data,
  (e) => {
    activeId.value = props.data?.Vehicles[0];
  }
);

function setActive(active) {
  activeId.value = active;
}
</script>

<style lang="scss">
.trf-vehicles {
  width: 100%;
  min-height: 800px;
  padding: 3rem 0;
  background-image: url(../../../images/gallery/vehicles.jpg);
  background-size: cover;
  background-position: center;
  &__wrap {
    width: 650px;
    line-height: 1.2;
    h2 {
      p {
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-weight: 300;
        text-align: center;
        color: var(--bg-primary);
        margin: 0;
        padding: 0;
        b {
          font-weight: 600;
        }
        strong {
          font-weight: 600;
        }
      }
    }
    p {
      padding: 0.7rem 0 1.4rem;
      line-height: 1.2;
      text-align: center;
      font-size: 18px;
      width: 550px;
      color: #012d46;
      margin: 0 auto;
    }
    .divider {
      width: 300px;
      background: #005f96;
      height: 3px;
      margin: 1rem auto;
    }
  }

  .vehicles-items {
    div {
      width: 250px;
      height: 150px;
      background-color: #ffff;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      p {
        background: #005f96;
        color: white;
        font-weight: 500;
        text-align: left;
        padding: 5px 10px;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        margin: 0;
      }

      &.active {
        p {
          background: #012d46 !important;
          color: #fff;
        }
      }

      &:hover {
        p {
          background: #005f962e;
          font-weight: 600;
        }
      }
    }
  }

  .vehicles-description {
    height: 500px;
    width: calc(250px * 3 + 4rem);
    form {
      background: #fff;
      padding: 2rem;
      width: 320px;
      margin-right: 6px;
      border-radius: 5px;
      h2 {
        font-size: 24px;
        padding-bottom: 1rem;
        color: #005f96;
      }
      input,
      textarea {
        border: none;
        background-color: #e9f4fa;
        outline: none;
        padding: 3px 6px;
        font-size: 14px;
      }
      input {
        height: 35px;
      }
      textarea {
        height: 60px;
        margin-bottom: 1rem;
      }
      label {
        font-size: 15px;
        padding-bottom: 1rem;
        color: #005f96;
      }
      .cc-button {
        width: 180;
        font-size: 16px;
        text-align: center;
        padding: 12px 26px !important;
        margin: 1rem auto;
        margin-bottom: 0;
        img {
          display: none !important;
        }
      }
    }
  }

  .vehicles-content {
    text-align: start;
    > img {
      width: 250px;
    }
    p {
      margin: 0;
      font-weight: 500;
    }
    .title {
      color: #012d46;
      font-weight: 700;
      font-size: 1rem;
      margin-top: 1rem;
    }

    p > b {
      color: #012d46;
      font-weight: 700;
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-vehicles {
    &__wrap {
      width: auto;
      h2 {
        p {
          font-size: 30px;
          b {
            font-weight: 600;
          }
        }
      }
      p {
        font-size: 18px;
        width: auto;
      }
      .divider {
        width: 150px;
        height: 2px;
        margin: 0.3rem auto;
      }
    }

    .vehicles-items {
      div {
        width: 250px;
        height: 150px;
        background-color: #ffff;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        p {
          font-weight: 500;
          text-align: left;
          padding: 5px 10px;
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          margin: 0;
        }

        &.active {
          p {
            background: #005f96 !important;
            color: #fff;
          }
        }

        &:hover {
          p {
            background: #005f962e;
            font-weight: 600;
          }
        }
      }
    }

    .vehicles-description {
      height: 500px;
      width: calc(250px * 3 + 4rem);
      form {
        background: #fff;
        padding: 2rem;
        width: 320px;
        margin-right: 6px;
        border-radius: 5px;
        h2 {
          font-size: 24px;
          padding-bottom: 1rem;
          color: #005f96;
        }
        input,
        textarea {
          border: none;
          background-color: #e9f4fa;
          outline: none;
          padding: 3px 6px;
          font-size: 14px;
        }
        input {
          height: 35px;
        }
        textarea {
          height: 60px;
          margin-bottom: 1rem;
        }
        label {
          font-size: 15px;
          padding-bottom: 1rem;
          color: #005f96;
        }
        .cc-button {
          width: 180;
          font-size: 16px;
          text-align: center;
          padding: 12px 26px !important;
          margin: 1rem auto;
          margin-bottom: 0;
          img {
            display: none !important;
          }
        }
      }
    }

    .vehicles-content {
      text-align: start;
      > img {
        width: 250px;
      }
      p {
        margin: 0;
        font-weight: 500;
      }
      .title {
        color: #012d46;
        font-weight: 700;
        font-size: 1rem;
        margin-top: 1rem;
      }

      p > b {
        color: #012d46;
        font-weight: 700;
      }
    }
  }
}
</style>
